<template>
  <div class="w-full min-h-screen flex flex-col items-center justify-start">
    <table
      v-if="farms.lenght !== 0"
      class="relative w-full flex flex-col text-white h-1/2 md:pt-6"
    >
      <tr
        class="fixed md:relative bg-gradient-to-r from-blue-darkest to-green-darkest z-10 w-full flex items-center  text-center shadow-sm"
      >
        <th class="w-1/6">Firma navn</th>
        <th class="w-3/6">Ejer</th>
        <th class="w-1/6">Telefon</th>
        <th class="w-1/6"></th>
      </tr>

      <div
        class="absolute md:relative w-full overflow-y-scroll h-3/4 md:h-full"
      >
        <tr
          v-for="farm in farms"
          :key="farm.id"
          class="bg-green-darkest border-2 border-green-dark mb-1 w-full flex items-center text-center cursor-pointer"
          @click="goToFarm(farm.id)"
        >
          <td class="w-1/6">{{ farm.companyName }}</td>
          <td class="w-3/6">
            {{ farm.ownerFirstName + " " + farm.ownerLastName }}
          </td>
          <td class="w-1/6">{{ farm.ownerPhone }}</td>
          <td class="w-1/6 flex justify-center"></td>
        </tr>
      </div>
    </table>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { farm } from "@/global/services/farm";
import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "page-login-service",
  setup() {
    const state = {
      email: ref(""),
      password: ref(""),
      errorCode: ref(null),
      errorMessage: ref(""),
      farms: ref(null)
    };

    farm.updateAllFarms();
    watchEffect(() => {
      if (farm.getAllFarms().length === 0) {
        state.farms.value = farm.getAllFarms();
      }
    });
    const router = useRouter();

    const goToFarm = idString => {
      const data = {
        id: idString
      };
      const func = firebase.functions().httpsCallable("serviceFarm");
      func(data)
        .then(result => {
          // Read result of the Cloud Function.
          router.push({ name: "Dashboard" });
          return result;
        })
        .catch(error => console.log("Functions return error", error));
    };

    return { ...state, goToFarm };
  }
};
</script>

<style lang="scss" scoped>
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}
</style>
